<script>
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  MailIcon,
} from "vue-feather-icons";
export default {
  data() {
    return {};
  },
  components: {
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    MailIcon,
  },
};
</script>

<template>
  <div>
    <footer class="footer footer-bar">
      <div class="container text-center">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="text-sm-left">
              <p class="mb-0">
                © 2020 - {{ new Date().getFullYear() }} {{$t('rights reserved')}}.
              </p>
            </div>
          </div>
          <!--end col-->

<!--          <div class="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">-->
<!--            <ul class="list-unstyled text-sm-right mb-0">-->
<!--              <li class="list-inline-item">-->
<!--                <a href="javascript:void(0)"-->
<!--                  ><img-->
<!--                    src="images/payments/american-ex.png"-->
<!--                    class="avatar avatar-ex-sm"-->
<!--                    title="American Express"-->
<!--                    alt=""-->
<!--                /></a>-->
<!--              </li>-->
<!--              <li class="list-inline-item ml-1">-->
<!--                <a href="javascript:void(0)"-->
<!--                  ><img-->
<!--                    src="images/payments/discover.png"-->
<!--                    class="avatar avatar-ex-sm"-->
<!--                    title="Discover"-->
<!--                    alt=""-->
<!--                /></a>-->
<!--              </li>-->
<!--              <li class="list-inline-item ml-1">-->
<!--                <a href="javascript:void(0)"-->
<!--                  ><img-->
<!--                    src="images/payments/master-card.png"-->
<!--                    class="avatar avatar-ex-sm"-->
<!--                    title="Master Card"-->
<!--                    alt=""-->
<!--                /></a>-->
<!--              </li>-->
<!--              <li class="list-inline-item ml-1">-->
<!--                <a href="javascript:void(0)"-->
<!--                  ><img-->
<!--                    src="images/payments/paypal.png"-->
<!--                    class="avatar avatar-ex-sm"-->
<!--                    title="Paypal"-->
<!--                    alt=""-->
<!--                /></a>-->
<!--              </li>-->
<!--              <li class="list-inline-item ml-1">-->
<!--                <a href="javascript:void(0)"-->
<!--                  ><img-->
<!--                    src="images/payments/visa.png"-->
<!--                    class="avatar avatar-ex-sm"-->
<!--                    title="Visa"-->
<!--                    alt=""-->
<!--                /></a>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </footer>
    <!--end footer-->
  </div>
</template>
