<template>
  <div class="pl-10" style="display: inline">
  <span class="text-4xl text-black">
    {{ title }}
  </span>
    <span class="text-4xl text-bold text-red-500">
      {{ displayText.join("") }}
    </span>
  </div>
</template>

<script>


/**
 * Typer component
 */

export default {
  props: {
    title: String,
    speed: {
      type: Number,
      default: 500
    },
    deleteSpeed: {
      type: Number,
      default: 166
    },
    durationBetweenWords: {
      type: Number,
      default: 1500
    },
    words: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      displayText: [],
      currentWord: "",
      wordIdx: 0,
      oldWordLength: 0,
      isWordDisplayedCompletely:false,
    };
  },
  computed: {
    // can adjust speeds based on other factors if needed
    TYPE_SPEED() {
      return this.speed
    },
    DELETE_SPEED() {
      return this.deleteSpeed
    },
    timeoutSpeed: {
      // getter
      get: function () {
        return this.TYPE_SPEED * 0.8
      },
      // setter
      set: function (newValue) {
        return newValue
      }
    },
  },
  mounted() {
    this.start();
  },
  methods: {
    start() {
      if (this.words && this.words.length > 0) {
        this.currentWord = this.words[this.wordIdx].split("");
        this.oldWordLength = this.currentWord.length;
        this.wordIdx++;
        this.animate = setTimeout(this.type, this.timeoutSpeed);
      }
    },
    type(word) {
       //debugger;
      if(this.isWordDisplayedCompletely === false){

      }
      // if typing...
      if (this.currentWord.length > 0) {
        this.displayText.push(this.currentWord.shift());
        // if done typing, then delete
      } else if (this.currentWord.length === 0 && this.displayText.length > 0) {
        // If word is typed completely
        if(this.displayText.length === this.oldWordLength && !this.isWordDisplayedCompletely){
          setTimeout(this.type, this.durationBetweenWords);
          this.isWordDisplayedCompletely = true;
          return;
        }
        this.timeoutSpeed = this.DELETE_SPEED;
        this.displayText.pop();
        // if done typing & deleting
      } else if (
          this.currentWord.length === 0 &&
          this.displayText.length === 0
      ) {
        // change words
        if (this.wordIdx < this.words.length) {
          this.currentWord = this.words[this.wordIdx].split("");
          this.wordIdx++;
          this.oldWordLength = this.currentWord.length;
          this.timeoutSpeed = this.TYPE_SPEED;
          this.displayText.push(this.currentWord.shift());
          this.isWordDisplayedCompletely = false;
        } else {
          // reset
          this.wordIdx = 0;
          this.currentWord = this.words[this.wordIdx].split("");
          this.displayText.push(this.currentWord.shift());
        }
      }

      setTimeout(this.type, this.timeoutSpeed);

    }
  }
}


</script>

<style scoped>

</style>