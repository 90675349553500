<template>
  <div>
    <!-- Navbar STart -->
    <header
        id="topnav"
        class="defaultscroll sticky"
        :class="{ 'bg-white': isWhiteNavbar === true }"
    >
      <div class="container">
        <!-- Logo container-->
        <div>
          <router-link class="logo" to="/" v-if="navLight !== true">
            <img src="images/zetta/logo2.png" height="24" alt=""/>
          </router-link>
          <router-link class="logo" to="/" v-else>
            <img src="images/zetta/logo2.png" class="l-dark" height="24" alt=""/>
            <img
                src="images/zetta/logo2.png"
                class="l-light"
                height="24"
                alt=""
            />
          </router-link>
        </div>


        <div class="menu-extras">
          <div class="menu-item">
            <!-- Mobile menu toggle-->
            <a
                class="navbar-toggle"
                @click="toggleMenu()"
                :class="{ open: isCondensed === true }"
            >
              <div class="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
            <!-- End mobile menu toggle-->
          </div>
        </div>

        <div id="navigation">
          <!-- Navigation Menu-->
          <ul
              class="navigation-menu"
              :class="{ 'nav-light': navLight === true }"
          >
            <li>
              <router-link class="side-nav-link-ref" v-scroll-to="'#home'" to="">{{ $t('home') }}</router-link>
            </li>

            <li>
              <router-link class="side-nav-link-ref" v-scroll-to="'#aboutus'" to="">{{ $t('About us') }}</router-link>
            </li>

            <li>
              <router-link to="" class="side-nav-link-ref" v-scroll-to="'#ourfeatures'">{{ $t('Our Services') }}
              </router-link>
            </li>

            <li>
              <router-link to="" class="side-nav-link-ref" v-scroll-to="'#howtowork'">{{ $t('work process') }}
              </router-link>
            </li>

            <li>
              <router-link to="" class="side-nav-link-ref" v-scroll-to="'#faq'">{{ $t('faq') }}</router-link>
            </li>


            <li>
              <router-link to="" class="side-nav-link-ref" v-scroll-to="'#contactus'">{{ $t('contact') }}</router-link>
            </li>
            <li>
              <b-select @change="changeLanguage($event)" v-model="lang" class="my-custom-select">
                <b-select-option value="ar">
                  {{ $t('Arabic') }}
                </b-select-option>
                <b-select-option value="en">
                  {{ $t('English') }}
                </b-select-option>
                <b-select-option value="tr">
                  {{ $t('Turkish') }}
                </b-select-option>
              </b-select>
            </li>

          </ul>
          <!--end navigation menu-->
          <!--          <div class="buy-menu-btn d-none">-->
          <!--            <a-->
          <!--              href="https://1.envato.market/4n73n"-->
          <!--              target="_blank"-->
          <!--              class="btn btn-primary"-->
          <!--              >تواصل معنا</a-->
          <!--            >-->
          <!--          </div>-->
          <!--end login button-->
        </div>
        <!--end navigation-->
      </div>
      <!--end container-->
    </header>
    <!--end header-->
    <!-- Navbar End -->
  </div>
</template>


<script>
/**
 * Navbar component
 */
export default {
  data() {
    return {
      isCondensed: false,
      lang: localStorage.getItem('lang') || 'ar',
    };
  },
  props: {
    isWhiteNavbar: {
      type: Boolean,
    },
    navLight: {
      type: Boolean,
    },
    isIcons: {
      type: Boolean,
    },
  },

  mounted: () => {
    window.onscroll = function () {
      onwindowScroll();
    };

    function onwindowScroll() {
      if (
          document.body.scrollTop > 50 ||
          document.documentElement.scrollTop > 50
      ) {
        document.getElementById("topnav").classList.add("nav-sticky");
      } else {
        document.getElementById("topnav").classList.remove("nav-sticky");
      }

      if (
          document.body.scrollTop > 100 ||
          document.documentElement.scrollTop > 100
      ) {
        document.getElementById("back-to-top").style.display = "inline";
      } else {
        document.getElementById("back-to-top").style.display = "none";
      }
    }

    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add("active");
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("active");
            const parent4 = parent3.parentElement;
            if (parent4) {
              const parent5 = parent4.parentElement;
              parent5.classList.add("active");
            }
          }
        }
      }
    }
  },
  methods: {
    /**
     * Toggle menu
     */
    toggleMenu() {
      this.isCondensed = !this.isCondensed;
      if (this.isCondensed) {
        document.getElementById("navigation").style.display = "block";
      } else document.getElementById("navigation").style.display = "none";
    },

    /**
     * Menu clicked show the submenu
     */
    onMenuClick(event) {
      event.preventDefault();
      const nextEl = event.target.nextSibling.nextSibling;

      if (nextEl && !nextEl.classList.contains("open")) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove("open");
        }
        nextEl.classList.add("open");
      } else if (nextEl) {
        nextEl.classList.remove("open");
      }
      return false;
    },
    changeLanguage(event) {
      localStorage.setItem("lang", event);
      this.$i18n.locale = event;

      if (event === 'ar') {
        this.setRtl();
      } else {
        this.setLtr();
      }
    },
    setRtl() {
      document.getElementById("theme-opt").setAttribute("href", "./css/style-rtl.css");
    },
    setLtr() {
      document.getElementById("theme-opt").setAttribute("href", "./css/style.css");
    }
  },
};
</script>


<style>
.side-nav-link-ref {
  font-size: 16px !important;
}

.my-custom-select {
  background-color: transparent;
  border: none;
  width: auto;
  padding-top: 19px !important;
  min-height: 62px;
  font-weight: 700;
  line-height: 24px;
  /*font-family: "Cairo", sans-serif;*/
  font-size: 16px;
  background-position-y: 64% !important;
}

.my-custom-select:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
</style>
