<template>
  <div>
    <Navbar/>
    <!-- Hero Start -->
    <section
        class="bg-home  bg-light d-flex align-items-center zetta-section"
        :class="$i18n.locale==='ar'?'':'dir-ltr'"
        id="home"
    >
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-8 col-md-9">
            <div class="title-heading mt-4">
              <h1 class="display-3 font-weight-bold mb-3">
                {{ $t('move to internet world') }}
                <span class="element text-primary">
                                    <typer
                                        title=""
                                        :words="[$t('website'), $t('mobile application'), $t('web system') ,$t('E-commerce')]"
                                        :speed="150" :durationBetweenWords="800"
                                    ></typer>
                                </span>
              </h1>
              <p class="para-desc text-muted">
                {{ $t('whole team') }}
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Start About Us -->
    <section class="section" id="aboutus">

      <div class="container mt-100 mt-60">
        <div class="row align-items-center">
          <div class="col-lg-5 col-md-6">
            <img src="images/zetta/SEO_SVG.svg" class="img-fluid" alt=""/>
          </div>
          <!--end col-->

          <div class="col-lg-7 col-md-6 mt-4 mt-lg-0 pt- pt-lg-0">
            <div class="ml-lg-5">
              <div class="section-title mb-3">
                <!--                <span class="badge badge-pill badge-soft-primary"-->
                <!--                >Meet the future</span-->
                <!--                >-->
                <h4 class="title my-3">{{ $t("we translate your project") }}</h4>
                <p class="text-muted para-desc">
                  {{ $t('about us main text') }}
                  <!--                  Start working with-->
                  <!--                  <span class="text-primary font-weight-bold">Landrick</span>-->
                  <!--                  that can provide everything you need to generate awareness,-->
                  <!--                  drive traffic, connect.-->
                </p>
                <p class="text-muted para-desc mb-0">
                  {{ $t('what are special about us') }}
                  <!--                  The most well-known dummy text is the 'Lorem Ipsum', which is-->
                  <!--                  said to have originated in the 16th century. Lorem Ipsum is-->
                  <!--                  composed in a pseudo-Latin language which more or less-->
                  <!--                  corresponds to 'proper' Latin. It contains a series of real-->
                  <!--                  Latin words.-->
                </p>
              </div>

              <ul class="list-unstyled text-muted">
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"
                  ><i class="uil uil-check-circle align-middle"></i></span
                  >{{ $t('quality') }}
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"
                  ><i class="uil uil-check-circle align-middle"></i></span
                  >{{ $t('speed') }}
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"
                  ><i class="uil uil-check-circle align-middle"></i></span
                  >{{ $t('accuracy') }}
                </li>
              </ul>

              <!--              <div class="watch-video mt-3">-->
              <!--                <a-->
              <!--                    href="https://1.envato.market/4n73n"-->
              <!--                    target="_blank"-->
              <!--                    class="btn btn-primary m-1"-->
              <!--                >اقرأ المزيد-->
              <!--                  <chevron-right-icon class="fea icon-sm"></chevron-right-icon-->
              <!--                  ></a>-->
              <!--                <a-->
              <!--                    href="javascript: void(0);"-->
              <!--                    v-b-modal.modal-->
              <!--                    class="btn btn-icon btn-pills video-play-icon btn-primary m-1"-->
              <!--                ><video-icon class="icons"></video-icon></a-->
              <!--                ><span-->
              <!--                  class="font-weight-bold text-uppercase small align-middle ml-1"-->
              <!--              >Watch Now</span-->
              <!--              >-->
              <!--                <b-modal-->
              <!--                    id="modal"-->
              <!--                    hide-footer-->
              <!--                    size="lg"-->
              <!--                    header-close-variant="white"-->
              <!--                    header-class="border-0"-->
              <!--                    content-class="border-0"-->
              <!--                    centered-->
              <!--                >-->
              <!--                  <youtube-->
              <!--                      video-id="jNTZpfXYJa4"-->
              <!--                      ref="youtube"-->
              <!--                      width="750"-->
              <!--                      height="450"-->
              <!--                      :player-vars="playerVars"-->
              <!--                  ></youtube>-->
              <!--                </b-modal>-->
              <!--              </div>-->
            </div>
          </div>
        </div>
        <!--end row-->
      </div>

    </section>

    <!--end About Us section-->

    <!-- Feature Start -->
    <section class="section" id="ourfeatures">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">{{$t('our services')}}</h4>
              <!--              <p class="text-muted para-desc mx-auto mb-0">-->
              <!--                Start working with-->
              <!--                <span class="text-primary font-weight-bold">Landrick</span> that-->
              <!--                can provide everything you need to generate awareness, drive-->
              <!--                traffic, connect.-->
              <!--              </p>-->
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div
              class="col-md-4 col-12"
              v-for="(item, index) of ourServices"
              :key="index"
          >
            <div class="features mt-5">
              <div class="image position-relative d-inline-block">
                <i :class="`${item.icon} h1 text-primary`"></i>
              </div>

              <div class="content mt-4">
                <h5>{{ item.title }}</h5>
                <p class="text-muted mb-0">{{ item.description }}</p>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- End feature -->


    <!-- How To Work  -->

    <section class="section" id="howtowork">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="section-title text-center mb-4 pb-2">
              <h6 class="text-primary">{{ $t('work stage') }}</h6>
              <h4 class="title mb-4">{{ $t('how we work') }}</h4>
              <p class="text-muted para-desc mx-auto mb-0">
                {{ $t('how we work slogan 1') }}
                <span class="text-primary font-weight-bold"> ZettaProg </span>
                {{ $t('how we work slogan 2') }}
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div class="col-md-4 mt-4 pt-2">
            <div
                class="card features work-process bg-transparent process-arrow border-0 text-center"
            >
              <div class="icons rounded h1 text-center text-primary px-3">
                <i class="uil uil-presentation-edit"></i>
              </div>

              <div class="card-body">
                <h4 class="title text-dark">{{ $t('work stages 1 title') }}</h4>
                <p class="text-muted mb-0">
                  {{ $t('work stages 1 text') }}
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
            <div
                class="card features work-process bg-transparent process-arrow border-0 text-center"
            >
              <div class="icons rounded h1 text-center text-primary px-3">
                <i class="uil uil-airplay"></i>
              </div>

              <div class="card-body">
                <h4 class="title text-dark">{{ $t('work stages 2 title') }}</h4>
                <p class="text-muted mb-0">
                  {{ $t('work stages 2 text') }}
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
            <div
                class="card features work-process bg-transparent d-none-arrow border-0 text-center"
            >
              <div class="icons rounded h1 text-center text-primary px-3">
                <i class="uil uil-image-check"></i>
              </div>

              <div class="card-body">
                <h4 class="title text-dark">{{ $t('work stages 3 title') }}</h4>
                <p class="text-muted mb-0">
                  {{ $t('work stages 3 text') }}
                </p>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <!--end container-->
    </section>
    <!--end section-->


    <section class="section pb-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h6>{{ $t('our partners slogan') }}</h6>
              <h4 class="title mb-4">{{ $t('our partners title') }}</h4>
              <!--              <p class="text-muted para-desc mx-auto mb-0">-->
              <!--                Start working with-->
              <!--                <span class="text-primary font-weight-bold">Landrick</span> that-->
              <!--                can provide everything you need to generate awareness, drive-->
              <!--                traffic, connect.-->
              <!--              </p>-->
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div class="col-12 mt-4 pt-2 pb-5">
            <carousel
                ref="carousel"
                dir="rtl"
                :per-page="3"
                class="owl-carousel owl-theme"
                :autoplay="true"
                :loop="true"
                :paginationEnabled="true"
                :centerMode="true"
                paginationColor="#ff5b69"
            >
              <slide>
                <div
                    class="card customer-testi text-center border rounded mx-2"
                >
                  <div class="card-body">
                    <img
                        src="images/zetta/theetreelogo.png"
                        class="img-fluid avatar avatar-ex-sm mx-auto"
                        alt=""
                    />
                    <p class="text-muted mt-4">
                      {{ $t('the e tree description') }}
                    </p>
                    <a href="https://theetree.com" target="_blank">
                      <h6 class="text-primary">{{ $t('the e tree') }}</h6>
                    </a>
                  </div>
                </div>
              </slide>

            </carousel>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>


    <!-- Testimonial Start -->
    <section
        class="section"
        style="background: url('images/3.jpg') center center"
    >
      <div class="bg-overlay"></div>
      <div class="container">
        <div class="row py-5 justify-content-center">
          <div class="col-lg-8 text-center">
            <carousel
                id="owl-fade"
                class="owl-carousel owl-theme"
                dir="ltr"
                :per-page="1"
                :loop="true"
                ref="carousel"
                :autoplay="true"
                :paginationEnabled="true"
                :centerMode="true"
                paginationColor="#ff5b69"
            >
              <Slide>
                <div class="customer-testi text-center mx-4">
                  <img
                      src="images/zetta/clients/mhmdalgharsi.jpeg"
                      class="img-fluid avatar avatar-small rounded-circle mx-auto shadow"
                      alt=""
                  />
                  <p
                      class="text-light para-dark h5 font-weight-normal font-italic mt-4 dir-rtl"
                  >
                    {{ $t('testimonials 1 part 1') }}
                    <span class="text-primary font-weight-bold">ZettaProg</span>
                    {{ $t('testimonials 1 part 2') }}
                  </p>
                  <ul class="list-unstyled mb-0">
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                  </ul>
                  <h6 class="text-light title-dark">{{ $t('testimonials 1 person') }}</h6>
                </div>
                <!--end customer testi-->
              </Slide>
              <Slide>
                <div class="customer-testi text-center mx-4  ">
                  <img
                      src="images/zetta/clients/avatar.jpg"
                      class="img-fluid avatar avatar-small rounded-circle mx-auto shadow"
                      alt=""
                  />
                  <p
                      class="text-light para-dark h5 font-weight-normal font-italic mt-4 dir-rtl"
                  >
                    {{ $t('testimonials 2') }}
                  </p>
                  <ul class="list-unstyled mb-0">
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                  </ul>
                  <h6 class="text-light title-dark">{{ $t('testimonials 2 person') }}</h6>
                </div>
                <!--end customer testi-->
              </Slide>

            </carousel>
            <!--end owl carousel-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->


    <!-- Start -->
    <section class="section" id="faq">
      <!-- goals -->
      <!--      <div class="container">-->
      <!--        <div class="row">-->
      <!--          <div class="col-lg-4 col-md-6 col-12">-->
      <!--            <div class="card features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0">-->
      <!--                        <span class="h1 icon2 text-primary">-->
      <!--                            <i class="uil uil-briefcase"></i>-->
      <!--                        </span>-->
      <!--              <div class="card-body p-0 content">-->
      <!--                <h5>Our Vision</h5>-->
      <!--                <p class="para text-muted mb-0">It is a long established fact that a reader will be of a page reader-->
      <!--                  will be of at its layout.</p>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          &lt;!&ndash;end col&ndash;&gt;-->

      <!--          <div class="col-lg-4 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">-->
      <!--            <div class="card features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0">-->
      <!--                        <span class="h1 icon2 text-primary">-->
      <!--                            <i class="uil uil-rocket"></i>-->
      <!--                        </span>-->
      <!--              <div class="card-body p-0 content">-->
      <!--                <h5>Our Mission</h5>-->
      <!--                <p class="para text-muted mb-0">It is a long established fact that a reader will be of a page reader-->
      <!--                  will be of at its layout.</p>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          &lt;!&ndash;end col&ndash;&gt;-->

      <!--          <div class="col-lg-4 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">-->
      <!--            <div class="card features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0">-->
      <!--                        <span class="h1 icon2 text-primary">-->
      <!--                            <i class="uil uil-crosshairs"></i>-->
      <!--                        </span>-->
      <!--              <div class="card-body p-0 content">-->
      <!--                <h5>Our Goal</h5>-->
      <!--                <p class="para text-muted mb-0">It is a long established fact that a reader will be of a page reader-->
      <!--                  will be of at its layout.</p>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          &lt;!&ndash;end col&ndash;&gt;-->
      <!--        </div>-->
      <!--        &lt;!&ndash;end row&ndash;&gt;-->
      <!--      </div>-->
      <!--end container-->


      <div class="container mt-100 mt-60">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="media">
              <help-circle-icon class="fea icon-ex-md text-primary mr-2 mt-1"></help-circle-icon>
              <div class="media-body">
                <h5 class="mt-0">{{ $t('faq 1 title') }} </h5>
                <p class="answer text-muted mb-0">
                  {{ $t('faq 1 answer') }}
                </p>
              </div>
            </div>
          </div>
          <!--end col-->


          <div class="col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div class="media">
              <help-circle-icon class="fea icon-ex-md text-primary mr-2 mt-1"></help-circle-icon>
              <div class="media-body">
                <h5 class="mt-0">{{ $t('faq 2 title') }}</h5>
                <p class="answer text-muted mb-0">
                  {{ $t('faq 2 answer') }}
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-6 col-12 mt-4 pt-2">
            <div class="media">
              <help-circle-icon class="fea icon-ex-md text-primary mr-2 mt-1"></help-circle-icon>
              <div class="media-body">
                <h5 class="mt-0">{{ $t('faq 3 title') }}
                </h5>
                <p class="answer text-muted mb-0">
                  {{ $t('faq 3 answer') }}
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-6 col-12 mt-4 pt-2">
            <div class="media">
              <help-circle-icon class="fea icon-ex-md text-primary mr-2 mt-1"></help-circle-icon>
              <div class="media-body">
                <h5 class="mt-0">{{ $t('faq 4 title') }}</h5>
                <p class="answer text-muted mb-0">
                  {{ $t('faq 4 answer') }}
                </p>
              </div>
            </div>
          </div>
          <!--end col-->


        </div>
        <!--end row-->

        <!--        <div class="row mt-5 pt-md-4 justify-content-center">-->
        <!--          <div class="col-12 text-center">-->
        <!--            <div class="section-title">-->
        <!--              <h4 class="title mb-4">Have Question ? Get in touch!</h4>-->
        <!--              <p class="text-muted para-desc mx-auto">Start working with <span class="text-primary font-weight-bold">Landrick</span>-->
        <!--                that can provide everything you need to generate awareness, drive traffic, connect.</p>-->
        <!--              <router-link to="/page-contact-two" class="btn btn-primary mt-4"><i class="mdi mdi-phone"></i> Contact us-->
        <!--              </router-link>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          &lt;!&ndash;end col&ndash;&gt;-->
        <!--        </div>-->
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->


    <section class="vh-100 d-flex align-items-center position-relative" id="contactus"
             style="background: url('images/contact-detail.jpg') center center;">
      <div class="bg-overlay bg-overlay-white"></div>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-12 offset-lg-12 col-md-7 offset-md-5">
            <div class="title-heading mt-5 pt-4">
              <h1 class="heading">{{ $t('contact us') }}</h1>
              <p class="text-dark">
                {{ $t('contact us text') }}
              </p>

              <div class="media contact-detail align-items-center mt-3">
                <div class="icon">
                  <mail-icon class="fea icon-m-md text-dark mr-3"></mail-icon>
                </div>
                <div class="media-body content">
                  <h5 class="title font-weight-bold mb-0">{{ $t('email') }} </h5>
                  <a href="mailto:info@zettaprog.com" class="text-primary">info@zettaprog.com</a>
                </div>
              </div>

              <div class="media contact-detail align-items-center mt-3">
                <div class="icon">
                  <phone-icon class="fea icon-m-md text-dark mr-3"></phone-icon>
                </div>
                <div class="media-body content">
                  <h5 class="title font-weight-bold mb-0">{{ $t('phone') }} </h5>
                  <a href="tel:+905523978553" class="text-primary" dir="ltr">+90 552 397 85 53</a>
                </div>
              </div>

              <!--              <div class="media contact-detail align-items-center mt-3">-->
              <!--                <div class="icon">-->
              <!--                  <map-pin-icon class="fea icon-m-md text-dark mr-3"></map-pin-icon>-->
              <!--                </div>-->
              <!--                <div class="media-body content">-->
              <!--                  <h5 class="title font-weight-bold mb-0">Location</h5>-->
              <!--                  <a href="javascript: void(0);" class="video-play-icon text-primary" v-b-modal.modal-1>View on Google-->
              <!--                    map</a>-->
              <!--                </div>-->
              <!--                <b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0"-->
              <!--                         id="modal-1" size="lg" centered body-class="p-0">-->
              <!--                  <iframe height="500" width="800"-->
              <!--                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"-->
              <!--                          style="border:0"></iframe>-->
              <!--                </b-modal>-->
              <!--              </div>-->
              <ul class="list-unstyled social-icon mb-0 mt-4">
                <li class="list-inline-item"><a href="https://www.facebook.com/zettaprog" target="_blank"
                                                class="rounded">
                  <facebook-icon class="fea icon-sm fea-social"></facebook-icon>
                </a></li>
                <!--                                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">-->
                <!--                                    <instagram-icon class="fea icon-sm fea-social"></instagram-icon>-->
                <!--                                </a></li>-->
                <!--                                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">-->
                <!--                                    <twitter-icon class="fea icon-sm fea-social"></twitter-icon>-->
                <!--                                </a></li>-->
                <li class="list-inline-item ml-1"><a href="https://www.linkedin.com/company/zettaprog" target="_blank"
                                                     class="rounded">
                  <linkedin-icon class="fea icon-sm fea-social"></linkedin-icon>
                </a></li>
                <!--                                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">-->
                <!--                                    <github-icon class="fea icon-sm fea-social"></github-icon>-->
                <!--                                </a></li>-->
                <!--                                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">-->
                <!--                                    <youtube-icon class="fea icon-sm fea-social"></youtube-icon>-->
                <!--                                </a></li>-->
                <!--                                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">-->
                <!--                                    <gitlab-icon class="fea icon-sm fea-social"></gitlab-icon>-->
                <!--                                </a></li>-->
              </ul>
              <!--end icon-->
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>

    <Footer/>
    <!-- Footer End -->
    <!--    <Switcher />-->
    <!-- Back to top -->
    <a
        href="javascript: void(0);"
        class="btn btn-icon btn-primary back-to-top"
        id="back-to-top"
        v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>

<script>
import {Carousel, Slide} from "vue-carousel";
import {
  UserIcon,
  MailIcon,
  KeyIcon,
  ArrowUpIcon,
  HelpCircleIcon,
  PhoneIcon,
  MapPinIcon,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  GithubIcon,
  YoutubeIcon, GitlabIcon,
  ChevronRightIcon,
  VideoIcon,

} from "vue-feather-icons";

import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/zettafooter";
import typer from "@/components/typer";

/**
 * Index-1
 */
export default {
  computed:{
    ourServices(){
      return [
        {
          icon: "uil uil-edit-alt",
          title: this.$t('developing web systems'),
          description:
              this.$t('web systems text'),
        },
        {
          icon: "uil uil-mobile-android",
          title: this.$t('developing mobile application'),
          description:
              this.$t('mobile application text'),
        },
        {
          icon: "uil uil-store",
          title: this.$t('e-commerce'),
          description:
              this.$t('e-commerce text'),
        },
        {
          icon: "uil uil-palette",
          title: this.$t('developing and design web sites'),
          description:
              this.$t('web sites text'),
        },
        {
          icon: "uil uil-cloud-computing",
          title: this.$t('hosting'),
          description:
              this.$t('hosting text'),
        },
        {
          icon: "uil uil-box",
          title: this.$t('all services are changeable'),
          description:
              this.$t('changeable text'),
        },
      ]
    }
  },
  data() {
    return {
      featuresData: [
        {
          icon: "uil uil-edit-alt",
          title: this.$t('developing web systems'),
          description:
              this.$t('web systems text'),
        },
        {
          icon: "uil uil-mobile-android",
          title: this.$t('developing mobile application'),
          description:
              this.$t('mobile application text'),
        },
        {
          icon: "uil uil-store",
          title: this.$t('e-commerce'),
          description:
              this.$t('e-commerce text'),
        },
        {
          icon: "uil uil-palette",
          title: this.$t('developing and design web sites'),
          description:
              this.$t('web sites text'),
        },
        {
          icon: "uil uil-cloud-computing",
          title: this.$t('hosting'),
          description:
              this.$t('hosting text'),
        },
        {
          icon: "uil uil-box",
          title: this.$t('all services are changeable'),
          description:
              this.$t('changeable text'),
        },
      ],
      playerVars: {
        autoplay: 1,
      },
    };
  },
  components: {
    Navbar,
    Switcher,
    Carousel,
    Slide,
    UserIcon,
    MailIcon,
    KeyIcon,
    ArrowUpIcon,
    Footer,
    HelpCircleIcon,
    PhoneIcon,
    MapPinIcon,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    GithubIcon,
    YoutubeIcon,
    GitlabIcon,
    ChevronRightIcon,
    VideoIcon,
    typer,

  },
  methods: {},
  created() {
    if(this.$i18n.locale==='ar'){
      document.getElementById("theme-opt").setAttribute("href", "./css/style-rtl.css");
    }else{
      document.getElementById("theme-opt").setAttribute("href", "./css/style.css");
    }
  }
};
</script>
<style>

</style>


